function DatePicker(props) {
  let range = {};
  if (props && props.lang && props.lang.__report === "รายงาน") {
    range.ranges = {
      วันนี้: [moment(), moment()],
      เมื่อวาน: [moment().subtract(1, "day"), moment().subtract(1, "day")],
      สัปดาห์นี้: [moment().startOf("week"), moment().endOf("week")],
      สัปดาห์ที่แล้ว: [
        moment().subtract(1, "weeks").startOf("week"),
        moment().subtract(1, "weeks").endOf("week"),
      ],
      เดือนนี้: [moment().startOf("month"), moment().endOf("month")],
      เดือนที่แล้ว: [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
      "7 วันที่แล้ว": [moment().subtract(6, "days"), moment()],
      "30 วันที่แล้ว": [moment().subtract(29, "days"), moment()],
    };
  } else {
    range.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "day"), moment().subtract(1, "day")],
      "This Week": [moment().startOf("week"), moment().endOf("week")],
      "Last Week": [
        moment().subtract(1, "weeks").startOf("week"),
        moment().subtract(1, "weeks").endOf("week"),
      ],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
    };
  }

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    $(inputRef.current).daterangepicker(
      {
        maxDate: moment(),
        startDate: moment(props.time.start_date),
        endDate: moment(props.time.end_date),
        alwaysShowCalendars: true,
        ranges: range.ranges,
        locale: {
          applyLabel: props.lang.__analytic_apply,
          cancelLabel: props.lang.__cancel,
          customRangeLabel: props.lang.__analytic_custom_range,
          format: "DD MMM YY",
        },
      },
      (startDate, endDate) => {
        let start = moment(startDate._d).format("YYYY-MM-DD");
        let end = moment(endDate._d).format("YYYY-MM-DD");
        if (start && end) {
          returnData(start, end);
        }
      }
    );
  }, [props.time]);

  returnData = (start, end) => {
    props.callbackData(
      Object.assign(
        {},
        {
          start_date: start,
          end_date: end,
        }
      )
    );
  };

  return (
    <div>
      <div class="sp-input-group" style={{ width: 230, marginTop: 10 }}>
        <input
          class="sp-input"
          id="daterangepicker"
          type="text"
          ref={inputRef}
        />
        <button
          class="add-on"
          onClick={() => document.getElementById("daterangepicker").click()}
        >
          <i class="icon-sort-down"></i>
        </button>
      </div>
    </div>
  );
}
